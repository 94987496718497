var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-form-view',{attrs:{"app":"working_paper","model":"workingpapertemplateheader","api-url":"working-paper/working-paper-template/","title":_vm.$t('menu.workingPaperTemplate'),"state":_vm.formData.state,"stateItems":_vm.stateItems,"approveItems":_vm.approveItems,"prepare-data":_vm.prepareData},scopedSlots:_vm._u([{key:"default",fn:function({ view }){return [_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"name","rules":"required","label":_vm.$t('fields.workingPaperTemplateName'),"view":view},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveName","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_name),callback:function ($$v) {_vm.$set(_vm.formData, "approve_name", $$v)},expression:"formData.approve_name"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"template category","type":"select","rules":"required","label":_vm.$t('fields.templateCategory'),"items":[
            {
              label: _vm.$t('label.auditPlan'),
              value: 'audit_plan'
            },
            {
              label: _vm.$t('label.audit'),
              value: 'audit'
            },
            { label: _vm.$t('label.auditAndAuditPlan'), value: 'both' }
          ],"view":view},model:{value:(_vm.formData.template_category),callback:function ($$v) {_vm.$set(_vm.formData, "template_category", $$v)},expression:"formData.template_category"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveTemplateCategory","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_template_category),callback:function ($$v) {_vm.$set(_vm.formData, "approve_template_category", $$v)},expression:"formData.approve_template_category"}}):_vm._e()],1),_c('v-col',{staticClass:"app-input--approve-wrapper",attrs:{"cols":"12","lg":"6"}},[_c('app-input',{attrs:{"name":"Master Working Paper Header","type":"select-server","label":_vm.$t('fields.masterWorkingPaperTemplateName'),"view":view,"rules":"required","binds":{
            apiUrl: 'working-paper/master-working-paper-header/?active=true'
          }},model:{value:(_vm.formData.master_working_paper_name_id),callback:function ($$v) {_vm.$set(_vm.formData, "master_working_paper_name_id", $$v)},expression:"formData.master_working_paper_name_id"}}),(_vm.isSentToApprove)?_c('app-input',{staticClass:"approve",attrs:{"name":"approveMasterWorkingPaperName","type":"switch","label":_vm.$t('fields.approve')},model:{value:(_vm.formData.approve_master_working_paper_name_id),callback:function ($$v) {_vm.$set(_vm.formData, "approve_master_working_paper_name_id", $$v)},expression:"formData.approve_master_working_paper_name_id"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('app-input',{attrs:{"name":"active","type":"switch","label":_vm.$t('fields.active'),"view":view},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}})],1)],1)]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})
}
var staticRenderFns = []

export { render, staticRenderFns }